import type { EventSeries } from 'core';

export const seriesSlugGroup = (data: EventSeries.Type): string => {
  if (data.slugPattern === 'country' && data.country) {
    return data.country.toLowerCase();
  }

  if (data.year) {
    return typeof data.year === 'number'
      ? data.year.toString()
      : data.year[0].toString();
  }

  return '';
};
