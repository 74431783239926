import { Group as GroupIcon } from '@mui/icons-material';
import { ButtonBase, Chip, Skeleton } from '@mui/material';
import type { FC } from 'react';
import usePromise from 'react-use-promise';
import { useCache } from '../../providers/cache.js';

export const GroupEntity: FC<{ groupId: string; link?: boolean }> = ({
  groupId,
  link,
}) => {
  const { group } = useCache();
  const [data, error, state] = usePromise(() => group.get(groupId), [groupId]);

  if (state === 'pending') return <Skeleton variant="rounded" />;
  if (state === 'rejected') return <>Error: {error.message}</>;
  if (!data) return null;

  if (!link) {
    return <Chip icon={<GroupIcon />} label={data.title} size="small" />;
  }

  return (
    <ButtonBase href={`#/group/${groupId}`}>
      <Chip
        icon={<GroupIcon />}
        label={data.title}
        size="small"
        sx={{ cursor: 'pointer' }}
      />
    </ButtonBase>
  );
};
