import { Notes as Icon } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Chip, useMediaQuery } from '@mui/material';
import type { FC } from 'react';

export const NotPublishedChip: FC<{ sx?: SxProps }> = ({ sx }) => (
  <Chip
    color="warning"
    label={
      !useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
        ? 'Not Published'
        : 'Draft'
    }
    size="small"
    icon={<Icon />}
    sx={sx}
  />
);
